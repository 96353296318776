<template>
  <div
    v-if="false && sagaPaperStore.isFrontPagePaper"
    class="flex flex-row justify-between items-center"
  >
    <!-- New B2C design -->
    <span
      :class="{
        '-mb-1 label-m': true,
        'headline-3xs': !true,
      }"
    >
      {{ computedLabel }}
    </span>

    <NuxtLink
      v-if="link"
      class="text-xs font-semibold text-gray-700 font-sans"
      :to="link"
      >{{ $t(linkLabel) }}</NuxtLink
    >
  </div>
  <div v-else class="flex flex-row items-center">
    <!-- Old design -->
    <img
      v-if="!true && !noIcon"
      :src="icon || '/A_Logomark.svg'"
      alt="Icon"
      class="w-5 h-5 inline-block mr-1"
    />
    <span class="label-m" :class="true ? '-mb-1' : 'text-red'">
      {{ computedLabel }}
    </span>
    <span
      v-if="link"
      class="uppercase text-black text-opacity-50 ml-auto label-s transition-opacity duration-100 hover:opacity-100"
    >
      <NuxtLink :to="link">{{ $t(linkLabel) }}</NuxtLink>
    </span>
  </div>
</template>

<script setup lang="ts">
const sagaPaperStore = useSagaPaperStore()

const nuxtApp = useNuxtApp()

const props = withDefaults(
  defineProps<{
    label?: string
    linkLabel?: string
    link?: string
    translateLabel?: boolean

    // Props for old label design
    icon?: string
    noIcon?: boolean
  }>(),
  { noIcon: false, label: '', linkLabel: 'ShowAll', translateLabel: true }
)

const computedLabel = computed(() => {
  if (props.label === 'MostRead' && !sagaPaperStore.isFrontPagePaper) {
    return `${labelOrTranslation.value} ${sagaPaperStore.activePaper.name}`
  } else {
    return labelOrTranslation.value
  }
})

const labelOrTranslation = computed(() => {
  if (props.translateLabel && nuxtApp.$te(props.label)) {
    return nuxtApp.$t(props.label)
  } else {
    return props.label
  }
})
</script>
